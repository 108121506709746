<template>
    <div class="all-page">
        <TopNav title="攻略详情" backPath="StrategyListIndex"></TopNav>
        <div class="all-page-content-box">
            <!-- <div v-html="">
                
            </div> -->
            <div class="title-box">
                <div class="title">{{strategyText.title}}</div>
                <div class="time-text">{{strategyText.release_time | changTimeType}}</div>
            </div>
            <div class="text" v-html="strategyText.content">
            </div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    export default {
        components: {
            TopNav
        },
        props: {
            strategyText:Object
        },
        data() {
            return {
                
            }
        },
        methods: {
           
        }
    }
</script>

<style scoped>
    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 11px;
        font-weight: 500;
        color: #333333;
    }
    .time-text,
    .text {
        font-size: 8.75px;
        font-weight: 400;
        color: #666666;
    }
    .text {
        padding-top: 6.25px;
    }
    .text {
        width: 100%;
    }
    /deep/.text img {
        width: 100% !important;
    }
 </style>
