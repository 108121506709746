<template>
    <div style="height: 100%; width: 100%; background-color: #F4F4F4;">
            <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'" :strategyText="strategyText"></Horizontal>
            <Vertical ref="Vertical" v-else
            :strategyText="strategyText"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Strategy/StrategyDetail/Horizontal/Index.vue'
    import Vertical from '@/components/Strategy/StrategyDetail/Vertical/Index.vue'


    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                strategyText:{}
            }
        },
        created() {
          this.getStragyDetail()
        },
        methods: {
           getStragyDetail() {
               let requestData = {
                   user_id:this.$store.state.userId,
                   token:this.$store.state.token,
                   news_id:this.$route.params.item.news_id,
               }
               this.$api.game.getStrategyDetail(requestData).then(res=> {
                   if (res.data.code === 200) {
                       this.strategyText = res.data.data
                   }
               })
               
           } 
        }
    }
</script>

<style scoped>
   
</style>
